import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Header from "../components/Header/Header";
import "../pages/PropertyDetails/PropertyDetails.scss";
import Footer from "../components/Footer/Footer";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import ViewPage from "../components/ViewPage/ViewPage";
import DetailsBanner from "../components/DetailsBanner/Banner";
import LocalArea from "../components/LocalArea/LocalArea";
import SimilarProperty from "../components/SimilarProperty/SimilarProperty";
import LocRatingMap from "../components/Map/loc-rating-map";
import { MapMarkerInfoBox } from "../components/Map/map-marker";
import { Link } from "gatsby";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PlayVideo from "../components/PlayVideo/PlayVideo";
import ReapitView from "../components/ReapitView";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import $ from "jquery";
import NotFoundPage from "../pages/404";
import classNames from "classnames";
import Axios from 'axios';
import BrandLogo from "../images/logo.svg";
import Seo from "../components/seo";
import _ from "lodash";
import { Helmet } from "react-helmet";

const PropertyQuery = gql(`
    query PropertyDetails($id: ID!) {
        property (id:$id) {
            _id
            id
            intro
            accomadation_summary
            address
            archive_after
            area
            available_from
            bathroom
            brochure
            bedroom
            building
            completion_date
            createdAt
            crm_area_id
            crm_id
            crm_negotiator_id
            crm_provider
            department
            description
            display_address
            epc
            extra
            floor_level
            floorarea_min
            floorarea_type
            floorarea_max
            floorplan
            images
            imagetransforms
            introtext
            latitude
            long_description
            longitude
            max_bedrooms
            max_price
            negotiator_email
            office_crm_id
            open_house
            parking
            parking_spaces
            postcode
            price
            price_qualifier
            property_url
            publish
            published_at
            reception
            rent
            room_details
            search_type
            situation
            slug
            special
            status
            style
            time_amended
            title
            updatedAt
            virtual_tour
            notes
        }
        offices(where: { Area_Guide_null: false }) {
            Area_Guide {
                _id
                Title
                Sm_Title
                Content
                Cta_Label
                Video_Url
                About_Cta_Link {
                    URL
                    _id
                }
                Italic_Text
                Tile_Image {
                    alternativeText
                    url
                }
            }
        }    
        globalModule { 
            Mortgage_Partner {
                Title
                Video_Url
                Tile_Image {
                    alternativeText
                    url
                }
                Mortgage_Email
                Description
            }
        }
        siteConfig {
			Phone
        }
    }
`)


const PropertyDetailsTemplate = (props) => {
    const mapRef = React.createRef(null);
    const [negotiator, setNegotiator] = useState(null);
    const propertyId = props.slug?.split('-').pop();
    // scroll header
    const [scroll, setScroll] = useState(false);
    // Property floorplan images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [photoIndexFloorplan, setPhotoIndexFloorplan] = useState(0);
    const [photoIndexEPC, setPhotoIndexEPC] = useState(0);
    const [isOpenEPC, setIsOpenEPC] = useState(false);
    const [isOpenFloor, setIsOpenFloor] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [video, showVideo] = useState(false);
    const [video360, show360Tour] = useState(false);
    const [shareIcons, setShareicons] = React.useState(false);

    const { data, loading, error } = useQuery(PropertyQuery, {
        variables: {
            id: propertyId
        }
    });

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 120 && !scroll) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        });
    }, []);

    useEffect(async() => {
        if(data?.property?.negotiator_email != null) {
            let url = process.env.GATSBY_STRAPI_SRC+"/teams?_where[Email_contains]="+data?.property?.negotiator_email;
            try {
                const { data } = await Axios.get(url, { headers: { Authorization: "Bearer " + process.env.GATSBY_STRAPI_AT }})
                if (data.length > 0) {
                    setNegotiator(data[0])
                }
            } catch (error) {
                console.error(error)
            }
        }
    }, [data?.property]);
    let mapItems = [];
    let mapItem = {};
    if(data && data.property) {
        mapItem['lat']  = data?.property?.latitude
        mapItem['lng']  = data?.property?.longitude
        const mapMarkerImageShowFlag = 0;
    
        let mapItemHtml = MapMarkerInfoBox(data?.property?.intro, mapMarkerImageShowFlag);
        mapItem['html'] = mapItemHtml;
        mapItems.push(mapItem);
    }

    //let openHouse = props?.location?.search.includes("open=true");
    let openHouse = ""
    let openHours = []

    if(data?.property){
        openHouse = data && data?.property && data?.property?.open_house
        openHours = data && data?.property && data?.property?.extra && data?.property?.extra?.open_house_dates
    } else{
        openHouse = false
    }

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    // Property images lightbox

    const openPropertyImageFloor = (e, ind) => {
        e.preventDefault();
        setPhotoIndexFloorplan(ind);
        setIsOpenFloor(true);
    }

    const openPropertyEPC = (e, ind) => {
        e.preventDefault();
        setPhotoIndexEPC(ind);
        setIsOpenEPC(true);
    }

    let processedImgs = JSON.stringify({});
    var propertyLightImages = [];
    var propertyLightImagesFloorplan = [];
    var propertyLightImagesEPC = [];

    if (data && data.property) {
        // ggfx
        if (data.property?.imagetransforms?.images_Transforms) {
            processedImgs = data.property.imagetransforms.images_Transforms;
        }
        // Property details images lightbox
        const propertyImages = data.property.images;
        if (propertyImages && propertyImages != 0) {
            if (_.isEmpty(propertyLightImages)) {
                propertyImages.map((img) => {
                if (!_.isEmpty(img.url)) {
                    propertyLightImages.push(img.url);
                }
                });
            }
        } else {
            propertyLightImages = propertyImages && propertyImages.filter(c => c.url != undefined).map(img => img.srcUrl);
        }
        // Property details images lightbox

        // Property details floorplan images lightbox
        const floorplanImages = data.property.floorplan;
        if (floorplanImages && floorplanImages != 0) {
            if (_.isEmpty(propertyLightImagesFloorplan)) {
                floorplanImages.map((img) => {
                if (!_.isEmpty(img.url)) {
                    propertyLightImagesFloorplan.push(img.url);
                }
                });
            }
        } else {
            propertyLightImagesFloorplan = floorplanImages && floorplanImages.map(img => img.srcUrl);
        }

        // EPC
        const epcImages = data.property.epc;
        if(epcImages && epcImages != 0) {
            if(_.isEmpty(propertyLightImagesEPC)) {
                epcImages.map((epc)=> {
                    if(!_.isEmpty(epc.url)) {
                        propertyLightImagesEPC.push(epc.url);
                    }
                })
            }
        }
        else {
            propertyLightImagesEPC = epcImages && epcImages.map(epc => epc.srcUrl)
        }
        // EPC
    }

    function fullScreenHandler() {
        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if(fullscreenElement == null) {
            showVideo(false);
        }
    }


    const shareurl = typeof window !== "undefined" ? window.location.href : "";


    const openShareicons = () => {
        setShareicons(true)
        if (shareIcons === true) {
            setShareicons(false)
        }
    }

    const trackerShare = event => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "Share - social icons",
            formType: event + " - " + shareurl,
            formId: "Share",
            formName: "Share",
            formLabel: "Share",
        })
    }

    if(loading) {
        return (
            <React.Fragment>
                <Header openHouse={openHouse} menuLinks={[]}  openHours={openHours}/>
                <Footer/>
            </React.Fragment>
        )
    }

    if(data && data.property == null) {
        return <NotFoundPage />
    }
    if(data && data.property.publish == false) {
        return <NotFoundPage />
    }
    
    return (
        <React.Fragment>
            <Seo
                title={`${data?.property?.building ? _.startCase(data?.property?.building) : "Property"} ${data?.property?.search_type == "sales" ? "for sale" : "to let"} with ${data?.property?.bedroom} bedrooms in ${data?.property?.display_address} at £${data?.property?.price?.toLocaleString()}`}
                description={`Find the details of ${data?.property?.building ? data?.property?.building : "property"} ${data?.property?.search_type == "sales" ? "for sale" : "to let"} with ${data?.property?.bedroom} bedrooms in ${data?.property?.display_address} at £${data?.property?.price?.toLocaleString()}. Request a viewing to get assistance in knowing more about this ${data?.property?.building ? data?.property?.building : "property"}.`}
                image={data?.property?.images?.[0]?.url}
                location={props.location}
            />
            <Helmet>
                <script type="application/ld+json">{`{
                    "@context": "https://schema.org",
                    "@type": "Offer",
                    "itemOffered": {
                        "@type": "House",
                        "name": "${data?.property?.intro}",
                        "description": "Find the details of ${data?.property?.building ? data?.property?.building : "property"} ${data?.property?.search_type == "sales" ? "for sale" : "to let"} with ${data?.property?.bedroom} bedrooms in ${data?.property?.display_address} at £${data?.property?.price?.toLocaleString()}. Request a viewing to get assistance in knowing more about this ${data?.property?.building ? data?.property?.building : "property"}.",
                        "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "${data?.property?.address?.house_number} , ${data?.property?.address?.street_name}",
                        "addressLocality": "${data?.property?.address?.town}",
                        "addressRegion": "${data?.property?.address?.area}",
                        "postalCode": "${data?.property?.address?.postcode}",
                        "addressCountry": "UK"
                        },
                        "numberOfBedrooms": ${data?.property?.bedroom || 0},
                        "numberOfBathroomsTotal": ${data?.property?.bathroom || 0},
                        "amenityFeature": [
                        {
                            "@type": "LocationFeatureSpecification",
                            "name": "Modern Kitchen",
                            "value": "true"
                        },
                        {
                            "@type": "LocationFeatureSpecification",
                            "name": "Spacious rooms",
                            "value": "true"
                        }
                        ],
                        "image": "${data?.property?.images?.[0]?.url}"
                    },
                    "price": "${data?.property?.price}",
                    "priceCurrency": "GBP",
                    "availability": "https://schema.org/InStock",
                    "url": "${data?.property_url}",
                    "seller": {
                        "@type": "RealEstateAgent",
                        "name": "TLC London",
                        "telephone": "+44 20 7373 1234",
                        "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "8 Hogarth Place",
                        "addressLocality": "London",
                        "addressRegion": "Greater London",
                        "postalCode": "SW5 0QT",
                        "addressCountry": "UK"
                        },
                        "url": "https://www.tlclondon.com"
                    }
     
                }`}
                </script>
            </Helmet>
            <Header openHouse={openHouse} property={data?.property} menuLinks={[]}  openHours={openHours}/>

            <div className={scroll ? "header property-details-menu-wrapper active" : "property-details-menu-wrapper"}>
            <Container fluid>
                <div className="d-flex flex-xl-row flex-column justify-content-between align-items-center property-details-wrapper">
                    <ul className="list-inline col-logo main-nav">
                        <li className="list-item-logo mx2">
                            <Link to="/">
                                <img src={BrandLogo} width={51}/>
                            </Link>
                        </li>
                    </ul>
                    <ul className="list-inline main-nav prop-nav-list">
                        <li className="list-item mx-3" onClick={() => {
                            $('html, body').animate({
                                scrollTop: $("#features-description").offset().top-130
                            }, 0)
                        }}>
                            <a className="web cursor-pointer ">Features and Description</a>
                            <a className="phone align-items-center cursor-pointer">Features</a>
                        </li>
                        <li className="list-item mx-3">
                            <a className="cursor-pointer" onClick={(e) => openPropertyImage(e, 0)}>Gallery</a>
                        </li>
                        {data?.property?.virtual_tour?.length > 0 && <li className="list-item mx-3">
                            <a
                                onClick={() => {
                                    showVideo(true);
                            }}>
                            Video</a>
                        </li>}
                        <li className="list-item mx-3">
                            <a className="cursor-pointer" onClick={(e) => openPropertyImageFloor(e, 0)}>Floorplan</a>
                        </li>
                        <li className="list-item mx-3">
                            <a className="cursor-pointer" onClick={(e) => openPropertyEPC(e, 0)}>EPC</a>
                        </li>
                        {data?.property?.brochure?.length > 0 && <li className="list-item mx-3">
                            <a href={data?.property?.brochure[0].srcUrl} target="_blank">brochure</a>
                        </li>}
                        {data?.property?.latitude && <li className="list-item mx-3">
                            <a className="cursor-pointer" onClick={() => {
                                let elementPosition = mapRef.current.getBoundingClientRect().top;
                                let offsetPosition = elementPosition - 130;
                                window.scrollBy({
                                    top: offsetPosition,
                                    behavior: "smooth"
                                });
                            }}>map</a>
                        </li>}
                    </ul>
                    <ul className="list-inline main-nav view-page py-0 nav-fixed-cta">
                        {(data?.property?.negotiator_email && negotiator != null) && <li className="link-block list-item mx-2">
                            <a href={`tel:${negotiator?.Mobile}`} className="phone-no">
                                <i className="icon-phone-grey"></i>
                                <b>{negotiator?.Mobile}</b>
                            </a>
                        </li>}
                        <li className="link-block list-item mx-2 d-flex flex-row align-items-center">
                            {shareIcons && (
                                <div onClick={openShareicons} className="property-share-social-icons2 mobile-details-socila-share pe-3 share-url icon-head">
                                    <FacebookShareButton
                                        onClick={() =>
                                            trackerShare("FacebookShareButton")
                                        }
                                        url={shareurl}
                                        className="my-share-button facebook-share mx-1"
                                    >
                                        <FacebookIcon
                                            size={32}
                                            round={false}
                                            borderRadius={`10`}
                                        />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        onClick={() =>
                                            trackerShare("TwitterShareButton")
                                        }
                                        url={shareurl}
                                        className="my-share-button twitter-share mx-1"
                                    >
                                        <TwitterIcon
                                            size={32}
                                            round={false}
                                            borderRadius={`10`}
                                        />
                                    </TwitterShareButton>
                                    <a
                                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareurl}`}
                                        target="_blank"
                                        onClick={() =>
                                            trackerShare("LinkedinShareButton")
                                        }
                                        className="my-share-button linkedin-share mx-1 d-inline"
                                    >
                                        <LinkedinIcon
                                            size={32}
                                            round={false}
                                            borderRadius={`10`}
                                            style={{ pointerEvents: "none"}}
                                        />
                                    </a>
                                    <WhatsappShareButton
                                        onClick={() =>
                                            trackerShare("WhatsappShareButton")
                                        }
                                        url={shareurl}
                                        className="my-share-button whatsapp-share mx-1"
                                    >
                                        <WhatsappIcon
                                            size={32}
                                            round={false}
                                            borderRadius={`10`}
                                        />
                                    </WhatsappShareButton>    
                                </div>
                            )}
                            <a onClick={openShareicons} className="cursor-pointer">
                                <i className="icon-share"></i>
                                <b>Share</b> 
                            </a>
                        </li>
                            
                        {data?.property?.brochure?.length > 0 && <li className=" link-block list-item mx-2">
                            <a href={data?.property?.brochure[0].srcUrl} target="_blank">brochure</a>
                        </li>}
                        {data?.property?.crm_id && <li className="link-block list-item mx-2">
                            <ReapitView
                                crmId={data?.property?.crm_id}
                                dangerouslySetInnerHTML={{ 
                                    __html: `
                                        <a class="btn text-white reapit-viewing-btn prop-btn" property.id=${data?.property?.crm_id} message.appointment.title="Viewing Requested">
                                            Book a viewing
                                        </a>
                                    `
                                }}
                            />
                        </li> }
                    </ul>
                </div>
                <div className="divider-line"></div>
            </Container>
            </div>
             {/* Property image lightbox */}
             {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImages[photoIndex]}
                    nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
                    prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                    }
                />
            )}
            {isOpenFloor && (
                <Lightbox
                    mainSrc={propertyLightImagesFloorplan[photoIndexFloorplan]}
                    nextSrc={propertyLightImagesFloorplan[(photoIndexFloorplan + 1) % propertyLightImagesFloorplan.length]}
                    prevSrc={propertyLightImagesFloorplan[(photoIndexFloorplan + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length]}
                    onCloseRequest={() => setIsOpenFloor(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndexFloorplan((photoIndexFloorplan + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndexFloorplan((photoIndexFloorplan + 1) % propertyLightImagesFloorplan.length)
                    }
                    wrapperClassName={classNames({ "hide-prev-next": data?.property?.floorplan?.length == 1 })}
                />
            )}
            {isOpenEPC && (
                <Lightbox
                    mainSrc={propertyLightImagesEPC[photoIndexEPC]}
                    nextSrc={propertyLightImagesEPC[(photoIndexEPC + 1) % propertyLightImagesEPC.length]}
                    prevSrc={propertyLightImagesEPC[(photoIndexEPC + propertyLightImagesEPC.length - 1) % propertyLightImagesEPC.length]}
                    onCloseRequest={() => setIsOpenEPC(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndexEPC((photoIndexEPC + propertyLightImagesEPC.length - 1) % propertyLightImagesEPC.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndexEPC((photoIndexEPC + 1) % propertyLightImagesEPC.length)
                    }
                    wrapperClassName={classNames({ "hide-prev-next": data?.property?.epc?.length == 1 })}
                />
            )}
            <div className="property-details">
                <Container>
                    <Row>
                        <Col xs="12" lg="6" className="order-3 order-lg-2 xl-5">
                            <ViewPage
                                data={data?.property}
                                openHouse={openHouse}
                                openHours={openHours}
                                mortgage={data?.globalModule?.Mortgage_Partner}
                                siteConfig={data?.siteConfig}
                            />
                        </Col>
                        <Col xs="12" lg="6" className="order-1 order-md-2 order-lg-3 xl-7" >
                            <DetailsBanner  
                                data={data?.property}
                                gotoLocation={() => {
                                    let elementPosition = mapRef.current.getBoundingClientRect().top;
                                    let offsetPosition = elementPosition - 100;
                                    window.scrollBy({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    });
                                }}
                                playVideo={() => {
                                    showVideo(true);
                                }}
                                openHouse={openHouse}
                                setIsOpen={() => setIsOpen(true)}
                                setIsOpenFloor={() => setIsOpenFloor(true)}
                                setIsOpenEPC={() => setIsOpenEPC(true)}
                            />
                        </Col>
                    </Row>
                </Container>
                {data?.property?.latitude &&  <div ref={mapRef}>
                    <LocRatingMap
                        data={mapItems}
                    />
                </div>}
                <LocalArea 
                    data={data?.offices?.[0]?.Area_Guide}
                />
                {data && data.property && <SimilarProperty
                    property={data?.property}
                />}
            </div>
            <Footer
                popularSearchDetails={true}
                searchtype={data?.property?.search_type}
                searchBedroomfield={data?.property?.bedroom}
                searchPtype={data?.property?.building}
                Searcharea={data?.property}
                price={data?.property?.price}
                title ={data?.property?.intro}
                showBreadcrumb = {true}
                isPropDetailsPage = {true}                
            />
            <div>
                {(data && data.property && data.property.virtual_tour?.length > 0) && <PlayVideo
                    isOpen={video}
                    videoId={data?.property?.virtual_tour?.[0]?.url}
                    stopPlay={showVideo}
                    width={'100%'} height={'100%'}
                    fullscreen={true}
                />}
            </div>
        </React.Fragment>
    )
}

export default PropertyDetailsTemplate;