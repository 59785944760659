import React, {useState, useEffect} from "react";
import CountDown from "../Countdown/CountDown";
import ContactCard from "../ContactCard/ContactCard";
import ProductFeatures from "../ProductFeatures/ProductFeatures";
import ProductDescription from "../ProductDescription/ProductDescription";
import ProductCalculator from "../ProductCalculator/ProductCalculator";
import ScrollAnimation from 'react-animate-on-scroll';
import moment from "moment";
import "./ViewPage.scss";
import ReactToPrint from "react-to-print";
import PrintPropertyDetails from "./PrintPropertyDetails";
import ReapitView from "../ReapitView";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import ContactFormPage from "../forms/contact";
import PropertyAvailableFrom from "../PropertyCardBig/PropertyAvailableFrom"

export class DetailsContent extends React.PureComponent {
    componentDidMount() {
        const printStylecss = '@page { size: potrait; size: 215.9mm 279.4mm } img { -webkit-print-color-adjust: exact; color-adjust: exact } ',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style'),
        title = document.getElementsByTagName('title');

        style.type = 'text/css';
        style.media = 'print';
        title[0].innerHTML = "Property details";
        if (style.styleSheet){
            style.styleSheet.cssText = printStylecss;
        } else {
            style.appendChild(document.createTextNode(printStylecss));
        }
        head.appendChild(style);

    }
    render() {
        return (
            <PrintPropertyDetails {...this.props}/>
        )
    }
}

export class PrintDetails extends React.Component {
    render() {
        return (
            <React.Fragment>
                <ReactToPrint
                    trigger={() => <a><i className="icon-printer"></i>Print</a>}
                    content={() => this.componentRef}
                    copyStyles={true}
                />
                <div className="d-none">
                    <DetailsContent ref={el => (this.componentRef = el)} {...this.props} />
                </div>
            </React.Fragment>
        )
    }
}

const ViewPage = (props) => {
    let newTime = new Date(moment().endOf('week').subtract("hours", 8));
    if(props?.data?.crm_id == "OBR240176"){
        newTime = new Date(moment("2024-11-15 15:59:59"));
    }
    const [negotiator, setNegotiator] = useState(null);
    const [show, setShow] = useState(false);

    const shareurl = typeof window !== "undefined" ? window.location.href : "";

    const [shareIcons, setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true)
        if (shareIcons === true) {
            setShareicons(false)
        }
    }
    let openHours = "";
    let start = -1;
    let end = 1;
    if(props.openHours && props.openHours.length > 0){
        props.openHours.map((open)=>{
            if(open[moment().format("YYYY-MM-DD")]){
                openHours = open[moment().format("YYYY-MM-DD")]
            }
        })
        if(openHours){
            start = moment().diff(moment(openHours.start_date))
            end = moment().diff(moment(openHours.end_date))
            newTime = new Date(moment(openHours.end_date)); 
        }
  
    }
   
    const trackerShare = event => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "Share - social icons",
            formType: event + " - " + shareurl,
            formId: "Share",
            formName: "Share",
            formLabel: "Share",
        })
    }
    useEffect(async() => {
        if(props?.data?.negotiator_email != null) {
            let url = process.env.GATSBY_STRAPI_SRC+"/teams?_where[Email_contains]="+props?.data?.negotiator_email;
            try {
                const { data } = await Axios.get(url, { headers: { Authorization: "Bearer " + process.env.GATSBY_STRAPI_AT }})
                if (data.length > 0) {
                    setNegotiator(data[0])
                }
            } catch (error) {
                console.error(error)
            }
        }
    }, [props?.data]);
    return (
        <React.Fragment>
            <section className="view-page">
                <ScrollAnimation animateIn="fadeInLeft">
                    <div className="about-properties">
                        <div className="properties-info">
                            <div className="properties-heading">
                                <h1 className="h2">{props?.data?.intro}</h1>
                                <span className="property-price">£ {props?.data?.price.toLocaleString()} {props?.data?.search_type == "lettings" ? "PW" : ""} {props?.data?.price_qualifier == "Offers In Excess" ? props.data.price_qualifier : ""}</span>
                            </div>
                            <p>{props?.data?.title}</p>
                        </div>
                        <PropertyAvailableFrom available_from={props.data.available_from} status={props.data.status} />
                        <div className="properties-icons">

                            {parseInt(props?.data?.bedroom) > 0 && 
                                <span className="text">
                                    <i className="icon-bedroom"></i>
                                    <span className="sm-text">{props?.data?.bedroom}</span>
                                </span>
                            }

                            {parseInt(props?.data?.bathroom) > 0 && 
                                <span className="text">
                                    <i className="icon-bath"></i>
                                    <span className="sm-text">{props?.data?.bathroom}</span>
                                </span>
                            }

                            {parseInt(props?.data?.reception) > 0 &&
                                <span className="text">
                                    <i className="icon-sofa"></i>
                                    <span className="sm-text">{props?.data?.reception}</span>
                                </span>
                            }

                            
                            <a href="javascript:;" onClick={openShareicons} className="text ms-auto me-0 d-md-none position-relative">
                                <i className="icon-share"></i>
                                <span className="sm-text text-link">Share</span>
                                {shareIcons && (
                                    <div onClick={openShareicons} className="share-url">
                                        <FacebookShareButton
                                            onClick={() =>
                                                trackerShare("FacebookShareButton")
                                            }
                                            url={shareurl}
                                            className="my-share-button facebook-share"
                                        >
                                            <FacebookIcon
                                                size={32}
                                                round={false}
                                                borderRadius={`10`}
                                            />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            onClick={() =>
                                                trackerShare("TwitterShareButton")
                                            }
                                            url={shareurl}
                                            className="my-share-button twitter-share"
                                        >
                                            <TwitterIcon
                                                size={32}
                                                round={false}
                                                borderRadius={`10`}
                                            />
                                        </TwitterShareButton>
                                        <LinkedinShareButton
                                            onClick={() =>
                                                trackerShare("LinkedinShareButton")
                                            }
                                            url={shareurl}
                                            className="my-share-button linkedin-share"
                                        >
                                            <LinkedinIcon
                                                size={32}
                                                round={false}
                                                borderRadius={`10`}
                                            />
                                        </LinkedinShareButton>
                                        <WhatsappShareButton
                                            onClick={() =>
                                                trackerShare("WhatsappShareButton")
                                            }
                                            url={shareurl}
                                            className="my-share-button whatsapp-share"
                                        >
                                            <WhatsappIcon
                                                size={32}
                                                round={false}
                                                borderRadius={`10`}
                                            />
                                        </WhatsappShareButton>    
                                    </div>
                                )}
                            </a>
                        </div>
                    </div>
                </ScrollAnimation>
                {props.openHouse && start >= 0 && end < 0 &&<div className="counter-block">
                    <span className="counter-title">Hurry, only a limited time left for this Open House.</span>
                    <CountDown defineTime={newTime}/>
                </div>}
                <div className="link-wrap">
                    <ReapitView
                        crmId={props?.data?.crm_id}
                        dangerouslySetInnerHTML={{
                            __html: `
                            <a href="javascript:;" class="btn reapit-viewing-btn" property.id=${props?.data?.crm_id} message.appointment.title="Viewing Requested">
                                    Book a viewing
                                </a>
                            `
                        }}
                    />
                </div>
                <div className="link-block link-block1">
                    <ul>
                        {props?.siteConfig?.Phone && <li>
                            <a href={`tel:${props?.siteConfig?.Phone}`} className="phone-no">
                                <i className="icon-phone-grey"></i>
                                {props?.siteConfig?.Phone}
                            </a> 
                        </li>}
                        {/* {negotiator != null && <li>
                            <a href={`tel:${negotiator?.Mobile}`} className="phone-no">
                                <i className="icon-phone-grey"></i>
                                {negotiator?.Mobile}
                            </a> 
                        </li>} */}
                        <li className="d-flex flex-row align-items-center position-relative" style={{ minHeight: 35 }}>
                            <a href="javascript:;" className="cursor-pointer" onClick={openShareicons}>
                                <i className="icon-share"></i>
                                Share
                            </a>
                            {shareIcons && (
                                <div onClick={openShareicons} className="share-url icons-right">
                                    <FacebookShareButton
                                        onClick={() =>
                                            trackerShare("FacebookShareButton")
                                        }
                                        url={shareurl}
                                        className="my-share-button facebook-share mx-1"
                                    >
                                        <FacebookIcon
                                            size={32}
                                            round={false}
                                            borderRadius={`10`}
                                        />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        onClick={() =>
                                            trackerShare("TwitterShareButton")
                                        }
                                        url={shareurl}
                                        className="my-share-button twitter-share mx-1"
                                    >
                                        <TwitterIcon
                                            size={32}
                                            round={false}
                                            borderRadius={`10`}
                                        />
                                    </TwitterShareButton>
                                    <LinkedinShareButton
                                        onClick={() =>
                                            trackerShare("LinkedinShareButton")
                                        }
                                        url={shareurl}
                                        className="my-share-button linkedin-share mx-1"
                                    >
                                        <LinkedinIcon
                                            size={32}
                                            round={false}
                                            borderRadius={`10`}
                                        />
                                    </LinkedinShareButton>
                                    <WhatsappShareButton
                                        onClick={() =>
                                            trackerShare("WhatsappShareButton")
                                        }
                                        url={shareurl}
                                        className="my-share-button whatsapp-share mx-1"
                                    >
                                        <WhatsappIcon
                                            size={32}
                                            round={false}
                                            borderRadius={`10`}
                                        />
                                    </WhatsappShareButton>    
                                </div>
                            )}
                        </li>
                        {/*<li>
                             <PrintDetails {...props}/> 
                        </li>*/}
                        {props?.data?.brochure?.length > 0 && <li>
                            <a href={props?.data?.brochure[0].srcUrl} target="_blank"><i className="icon-printer"></i>Print</a>
                        </li>}
                    </ul>
                    
                </div>
                
                {negotiator != null &&  
                <div className="contact-card">
                    <ContactCard
                        blockImg={negotiator.Tile_Image.url}
                        blockTitle={`Sell or Let your property`}
                        blockContent={`We are the Kensington and Chelsea garden square specialists. Interested in selling or letting, get in touch with our team.`}
                        blockBtn={`Speak to our team`}
                        blockLink={`/about/meet-the-team/${negotiator.Url}`}
                        blockVideo={negotiator.Video_Url}
                    />
                </div>
                }
                {/* <div className="link-block mb-4"/> */}
                {/* {props?.data?.notes && <ProductFeatures
                    data={props?.data}
                />} */}
                <ProductDescription
                    data={props?.data}
                />
                {props?.data?.search_type !== "lettings" && <ProductCalculator
                    data={props?.data}
                    mortgage={props?.mortgage}
                />}
                {props?.data?.search_type !== "lettings" && <p className="info-text">
                    <strong>Speak with an advisor</strong> <a onClick={() => setShow(true)} className="cursor-pointer">Click here</a> to speak with our mortgage expert.
                </p>}

                {/* <div className="map-block d-md-none">
                    <h2>Situated in Bramham Gardens, a stunning square home to many events
                        throughout the year.
                    </h2>
                    <div className="map-img">
                        <img src={map} alt=""/>
                    </div>
                </div> */}
            </section>
            <Modal className="form-popup" onHide={() => setShow(false)} show={show} contentClassName="p-0" size="sm">
                <Modal.Body className="p-5">
                    <div className="card-title h5 text-center mb-3">Speak with our mortgage expert</div>
                    <button onClick={() => setShow(false)} type="button" className="close-modal">
                        <i className="icon-close-modal"></i>
                    </button> 
                    <div className="form-block career-form">
                        <ContactFormPage email={props?.mortgage?.Mortgage_Email}/>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default ViewPage
